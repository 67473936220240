.carousel-control-next, .carousel-control-prev {
  opacity: 1;
  width: 5%;
}
/* 
img.credera {
  display: block;
  margin: auto;
} */

.modal-body {
  background-color: #DDDDDD;
}

.carousel-caption {
  right: 10%;
  left: 10%;
}

.carousel-caption > ul {
  list-style-type: circle;
  font-family: 'Josefin Slab', serif;
  font-size: 1.2em;
}

.carousel-caption > h1 {
  font-family: 'Rock Salt', serif;
  font-size: 2.9vw;
  color: #DDDDDD;
}
.carousel-caption > h3 {
  font-family: 'Josefin Slab', serif;
  font-size: 2.4vw;
  color: black;
  font-weight: bold;
}

h1.long {
  font-size: 2vw;
}

.carousel-caption > li {
  text-align: justify;
}
ul {
  padding-inline-start: 1em;
}

@media only screen and (max-width: 360px) {
  .carousel-caption > h1 {
    font-size: 7vw;
  }
  .carousel-caption > h3 {
    font-size: 6vw;
  }
   h1.long {
    font-size: 5vw;
  } 
  .carousel-caption > ul {
    font-size: .8em;
  } 
}  

@media only screen and (max-width: 450px) and (min-width: 361px) {
  .carousel-caption > h1 {
    font-size: 5vw;
  }
  .carousel-caption > h3 {
    font-size: 5vw;
  }
   h1.long {
    font-size: 5vw;
  } 
  .carousel-caption > ul {
    font-size: .87em;
  } 
}  

@media only screen and (max-width: 750px) and (min-width: 451px) {

}  


@media only screen and (max-width: 1000px) and (min-width: 451px){
  .carousel-caption > h1 {
    font-size: 3vw;
  }
  .carousel-caption > h3 {
    font-size: 2vw;
  }
  h1.long {
    font-size: 2vw;
  }
  .carousel-caption {
    right: 5%;
    left: 5%;
  }
  .carousel-caption > ul {
    font-size: 1.1em;
  }
}