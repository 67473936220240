.navigation {
  margin-top: 15px;
}

.navbar-brand {
  /* font-weight: bold; */
  font-family: 'Rock Salt', cursive;
  font-size: 1.2vw;
  
}

.navbar-light .navbar-brand {
  color: #CC8888;
}

.nav-link {
  font-family: 'Josefin Slab', serif;
  font-size: 1.3vw;
  font-weight: bold;
  padding-right: 1em;
}

.navbar-light .navbar-nav .nav-link {
  padding-right: 1em;
}

@media only screen and (max-width: 450px)  {
  .navbar-brand {
    font-size: 4vw;
  }
  .nav-link {
    font-size: 5vw;
  }
} 

@media only screen and (max-width: 1050px) and (min-width: 451px) {
  .navbar-brand {
    font-size: 3vw;
  }
  .nav-link {
    font-size: 3vw;
  }
}  