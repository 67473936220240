/* @media (min-width: 576px) {
  .card-deck .card {
    margin-right: 20px;
    margin-left: 20px;
  }
} */

h1.center.heading.interactive {
  padding-top: .2em;
  font-size: 5vw;
  letter-spacing: 0.1em;
}
h1.center.heading.resume {
  padding-bottom:.3em;
  font-size: 5vw;
  letter-spacing: 0.1em;
}

.card-title > .btn {
  margin-top: calc(50% - 2vw);
  font-family: 'Josefin Slab', serif; 
  color:  #DDDDDD;
  font-size: 2vw;
  font-weight: bold;
}

.btn-primary {
  background-color: #36535B;
  border-color: #36535B;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open {
  color: #948f8c;
  background-color:  #d2b3b0 !important;
  border-color: #36535B !important; /*set the color you want here*/
}


/* @media (max-width: 768px) {
  .col-md-6 {
    flex: 0 0 80%;
    max-width: 80%;
  }
} */

@media only screen and (max-width: 320px) {
  .card-title > .btn {
    margin-top: calc(50% - 5vw);
    font-size: 5vw;
  }
  h1.center.heading.interactive, h1.center.heading.resume {
    font-size: 10vw;
  }
}  

@media only screen and (max-width: 450px) and (min-width: 321px) {
  h1.center.heading.interactive, h1.center.heading.resume {
    font-size: 10vw;
  }
  .card-title > .btn {
    margin-top: calc(50% - 5vw);
    font-size: 5vw;
  }
}  

@media only screen and (max-width: 1200px) and (min-width: 451px) {
  h1.center.heading.interactive, h1.center.heading.resume {
    font-size: 8vw;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .card-title > .btn {
    margin-top: calc(50% - 3vw);
    font-size: 3vw;
  }
}  
