body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navigation {
  margin-top: 15px;
}

.navbar-brand {
  /* font-weight: bold; */
  font-family: 'Rock Salt', cursive;
  font-size: 1.2vw;
  
}

.navbar-light .navbar-brand {
  color: #CC8888;
}

.nav-link {
  font-family: 'Josefin Slab', serif;
  font-size: 1.3vw;
  font-weight: bold;
  padding-right: 1em;
}

.navbar-light .navbar-nav .nav-link {
  padding-right: 1em;
}

@media only screen and (max-width: 450px)  {
  .navbar-brand {
    font-size: 4vw;
  }
  .nav-link {
    font-size: 5vw;
  }
} 

@media only screen and (max-width: 1050px) and (min-width: 451px) {
  .navbar-brand {
    font-size: 3vw;
  }
  .nav-link {
    font-size: 3vw;
  }
}  


h2.center.aattributes {
  font-family: 'Josefin Slab', serif;
  font-size: 3vw;
  color: #36535B;
  display: block;
}

.homePicture {
  max-width: 95%;
  padding-top: 1.5em;
}

.homeMainRow {
  padding-top: 1em;
}

.icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -.4em;
}

@media only screen and (max-width: 450px) {
  h1.center.heading.amanda {
    font-size: 14vw;
  }
  h2.center.aattributes {
    font-size: 8vw;
  }
}  

@media only screen and (max-width: 1024px) and (min-width: 451px) {
  h1.center.heading.amanda {
    font-size: 7vw;
  }
  h2.center.aattributes {
    font-size: 4vw;
  }
}  
body {
  background-color: #DDDDDD;
}

.center {
  position: relative;
  text-align:center;
}

.centerComponent {
  margin: auto;
  display: block;
}

h1.center.heading {
  font-family: 'Rock Salt', cursive;
  font-size: 7vw;
  letter-spacing: .05em;
  color: #CC8888
}





/* @media (min-width: 576px) {
  .card-deck .card {
    margin-right: 20px;
    margin-left: 20px;
  }
} */

h1.center.heading.interactive {
  padding-top: .2em;
  font-size: 5vw;
  letter-spacing: 0.1em;
}
h1.center.heading.resume {
  padding-bottom:.3em;
  font-size: 5vw;
  letter-spacing: 0.1em;
}

.card-title > .btn {
  margin-top: calc(50% - 2vw);
  font-family: 'Josefin Slab', serif; 
  color:  #DDDDDD;
  font-size: 2vw;
  font-weight: bold;
}

.btn-primary {
  background-color: #36535B;
  border-color: #36535B;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open {
  color: #948f8c;
  background-color:  #d2b3b0 !important;
  border-color: #36535B !important; /*set the color you want here*/
}


/* @media (max-width: 768px) {
  .col-md-6 {
    flex: 0 0 80%;
    max-width: 80%;
  }
} */

@media only screen and (max-width: 320px) {
  .card-title > .btn {
    margin-top: calc(50% - 5vw);
    font-size: 5vw;
  }
  h1.center.heading.interactive, h1.center.heading.resume {
    font-size: 10vw;
  }
}  

@media only screen and (max-width: 450px) and (min-width: 321px) {
  h1.center.heading.interactive, h1.center.heading.resume {
    font-size: 10vw;
  }
  .card-title > .btn {
    margin-top: calc(50% - 5vw);
    font-size: 5vw;
  }
}  

@media only screen and (max-width: 1200px) and (min-width: 451px) {
  h1.center.heading.interactive, h1.center.heading.resume {
    font-size: 8vw;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .card-title > .btn {
    margin-top: calc(50% - 3vw);
    font-size: 3vw;
  }
}  

.carousel-control-next, .carousel-control-prev {
  opacity: 1;
  width: 5%;
}
/* 
img.credera {
  display: block;
  margin: auto;
} */

.modal-body {
  background-color: #DDDDDD;
}

.carousel-caption {
  right: 10%;
  left: 10%;
}

.carousel-caption > ul {
  list-style-type: circle;
  font-family: 'Josefin Slab', serif;
  font-size: 1.2em;
}

.carousel-caption > h1 {
  font-family: 'Rock Salt', serif;
  font-size: 2.9vw;
  color: #DDDDDD;
}
.carousel-caption > h3 {
  font-family: 'Josefin Slab', serif;
  font-size: 2.4vw;
  color: black;
  font-weight: bold;
}

h1.long {
  font-size: 2vw;
}

.carousel-caption > li {
  text-align: justify;
}
ul {
  -webkit-padding-start: 1em;
          padding-inline-start: 1em;
}

@media only screen and (max-width: 360px) {
  .carousel-caption > h1 {
    font-size: 7vw;
  }
  .carousel-caption > h3 {
    font-size: 6vw;
  }
   h1.long {
    font-size: 5vw;
  } 
  .carousel-caption > ul {
    font-size: .8em;
  } 
}  

@media only screen and (max-width: 450px) and (min-width: 361px) {
  .carousel-caption > h1 {
    font-size: 5vw;
  }
  .carousel-caption > h3 {
    font-size: 5vw;
  }
   h1.long {
    font-size: 5vw;
  } 
  .carousel-caption > ul {
    font-size: .87em;
  } 
}  

@media only screen and (max-width: 750px) and (min-width: 451px) {

}  


@media only screen and (max-width: 1000px) and (min-width: 451px){
  .carousel-caption > h1 {
    font-size: 3vw;
  }
  .carousel-caption > h3 {
    font-size: 2vw;
  }
  h1.long {
    font-size: 2vw;
  }
  .carousel-caption {
    right: 5%;
    left: 5%;
  }
  .carousel-caption > ul {
    font-size: 1.1em;
  }
}
.table-dark {
  background-color: #36535B;
}

th {
  color: #CC8888;
  font-family: 'Josefin Slab', serif;
  font-weight: bold;
  font-size: 1.05em;
}

td {
  color: #DDDDDD;
  font-family: 'Josefin Slab', serif;
  font-size: 1.05em;
}
