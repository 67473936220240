body {
  background-color: #DDDDDD;
}

.center {
  position: relative;
  text-align:center;
}

.centerComponent {
  margin: auto;
  display: block;
}

h1.center.heading {
  font-family: 'Rock Salt', cursive;
  font-size: 7vw;
  letter-spacing: .05em;
  color: #CC8888
}



