

h2.center.aattributes {
  font-family: 'Josefin Slab', serif;
  font-size: 3vw;
  color: #36535B;
  display: block;
}

.homePicture {
  max-width: 95%;
  padding-top: 1.5em;
}

.homeMainRow {
  padding-top: 1em;
}

.icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -.4em;
}

@media only screen and (max-width: 450px) {
  h1.center.heading.amanda {
    font-size: 14vw;
  }
  h2.center.aattributes {
    font-size: 8vw;
  }
}  

@media only screen and (max-width: 1024px) and (min-width: 451px) {
  h1.center.heading.amanda {
    font-size: 7vw;
  }
  h2.center.aattributes {
    font-size: 4vw;
  }
}  