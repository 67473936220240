.table-dark {
  background-color: #36535B;
}

th {
  color: #CC8888;
  font-family: 'Josefin Slab', serif;
  font-weight: bold;
  font-size: 1.05em;
}

td {
  color: #DDDDDD;
  font-family: 'Josefin Slab', serif;
  font-size: 1.05em;
}